import React from 'react';
import { AlertColor, Alert as AlertComponent } from '@mui/material';
import layoutStyles from '../../../stylesheets/components/layout.module.scss';

interface AlertInterface {
  id?: string;
  severity: AlertColor;
  children: React.ReactNode;
}

const Alert = ({ id, severity, children }: AlertInterface) => {
  return (
    <AlertComponent id={id} className={layoutStyles.mb12} severity={severity}>
      {children}
    </AlertComponent>
  );
};

export default Alert;
